import {
  CardElement,
  IbanElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { fetchBootstrapData as fetchBootstrapDataAction } from "bootstrap/redux/bootstrapReducer";
import PaymentMethodTypeSelector from "payments/components/PaymentMethodTypeSelector";
import NativePayForm from "payments/containers/NativePayForm";
import StripeCardForm from "payments/containers/StripeCardForm";
import StripeIbanForm from "payments/containers/StripeIbanForm";
import { createSetupIntent as createSetupIntentAction } from "payments/redux/modules/stripePaymentMethod";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { notifyBugsnag } from "util/bugsnag";
import { CREATE_PAYMENT_INTENTS_URL } from "../../util/constants";
import xhr, { createApiErrorAlert } from "../../util/xhr";

import { defineMessages, injectIntl } from "react-intl";
import { camelCaseKeys } from "util/casing";
import { HANDLE_STRIPE_REDIRECT } from "../../build/routes/apiRoutes";

import { experiments } from "../../enums/experiments";
import { useExperiment } from "../../services/ExperimentService";

const messages = defineMessages({
  nativePayRequestCompanyLabel: {
    id: "editPayment.nativePayRequestCompanyLabel",
    defaultMessage: "TaskRabbit, Inc.",
  },
});

const PaymentMethodForm = ({
  availablePaymentMethodTypes,
  debitAuthorizationMandate,
  setPaymentMethodCallback,
  createSetupIntent,
  fetchBootstrapData,
  handlePaymentMethodChange,
  name,
  email,
  countryIsoCode,
  currencyCode,
  intl,
}) => {
  const { variationKey } = useExperiment(experiments.SPE_ENABLED.key);

  const speEnabled = variationKey === experiments.SPE_ENABLED.variants.ENABLED;

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const [canUseGooglePay, setCanUseGooglePay] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState();
  const [loading, setLoading] = useState(false);
  const [nativePaySetupIntentResponse, setNativePaySetupIntentResponse] =
    useState();

  useEffect(() => {
    fetchBootstrapData();
  }, [fetchBootstrapData]);

  // Payment Method Type
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState();
  useEffect(() => {
    if (!selectedPaymentMethodType) {
      setSelectedPaymentMethodType((availablePaymentMethodTypes || [])[0]);
    }
  }, [
    availablePaymentMethodTypes,
    selectedPaymentMethodType,
    setSelectedPaymentMethodType,
  ]);

  // SEPA Billing Details
  const [sepaBillingDetails, setSepaBillingDetails] = useState({
    name,
    email,
  });
  useEffect(() => {
    setSepaBillingDetails({ name, email });
  }, [name, email, setSepaBillingDetails]);

  // Stripe Submission
  const createPaymentIntent = useCallback(
    async (confirmationToken, paymentMethodType, returnUrl) => {
      return xhr
        .post(CREATE_PAYMENT_INTENTS_URL, {
          confirmation_token: confirmationToken,
          payment_method_type: paymentMethodType,
          return_url: returnUrl,
        })
        .catch((e) => {
          dispatch(createApiErrorAlert(e));
        });
    },
    [dispatch]
  );
  const handleStripeSubmit = useCallback(async () => {
    if (speEnabled) {
      if (stripe && elements) {
        try {
          const { error: submitError, selectedPaymentMethod } = await elements.submit();
          if (submitError) {
            notifyBugsnag("SetupIntentError", submitError);
            return null;
          }
          const { error, confirmationToken } =
            await stripe.createConfirmationToken({
              elements,
            });

          if (error) {
            notifyBugsnag("SetupIntentError", error);
            return null;
          }

          const paymentIntent = await createPaymentIntent(
            confirmationToken.id,
            selectedPaymentMethod,
            `${window.location.origin}${HANDLE_STRIPE_REDIRECT}${window.location.search}`
          );

          if (paymentIntent.camelCasedData.status === "requires_action") {
            const { error: nextActionError, setupIntent } =
              await stripe.handleNextAction({
                clientSecret: paymentIntent.camelCasedData.clientSecret,
              });
            return await Promise.all([
              {
                paymentMethod: setupIntent.payment_method,
                error: nextActionError,
              },
              {
                paymentMethodType: setupIntent.payment_method_types[0],
              },
            ]);
          }

          return await Promise.all([
            paymentIntent,
            {
              paymentMethodType: confirmationToken.payment_method_preview.type,
            },
          ]);
        } catch (e) {
          notifyBugsnag("SetupIntentError", e);
          return null;
        }
      }
    } else {
      try {
        const setupIntentCall = await createSetupIntent({
          win: window,
          paymentMethodType:
            selectedPaymentMethodType === "native_pay"
              ? "card"
              : selectedPaymentMethodType,
        });
        const setupIntent = setupIntentCall.data;
        const clientSecret = setupIntent.client_secret;
        switch (selectedPaymentMethodType) {
          case "card":
            return await stripe.confirmCardSetup(clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            });
          case "sepa_debit":
            return await stripe.confirmSepaDebitSetup(clientSecret, {
              payment_method: {
                sepa_debit: elements.getElement(IbanElement),
                billing_details: sepaBillingDetails,
              },
            });
          case "native_pay":
            return await Promise.resolve(nativePaySetupIntentResponse);
          default:
            return null;
        }
      } catch (e) {
        notifyBugsnag("SetupIntentError", e);
        return null;
      }
    }
  }, [
    speEnabled,
    stripe,
    elements,
    createPaymentIntent,
    createSetupIntent,
    selectedPaymentMethodType,
    sepaBillingDetails,
    nativePaySetupIntentResponse,
  ]);

  /*
   * The PaymentMethodForm is not a complete form. It only includes the inputs
   * and should be nested inside a form (booking flow, billing info edit). That
   * external form is responsible for triggering the submission of payment method
   * info to Stripe. Use the setPaymentMethodCallback prop to pull the handleStripeSubmit
   * callback from this component, and call that callback upon form submission.
   */
  useEffect(() => {
    setPaymentMethodCallback(handleStripeSubmit);
  }, [setPaymentMethodCallback, handleStripeSubmit]);

  const onPaymentElementChange = useCallback(
    (event) => {
      handlePaymentMethodChange(event.complete);
    },
    [handlePaymentMethodChange]
  );
  // Apple or Google Pay
  // We need to be checking for this here, so that we can show the tab
  useEffect(() => {
    let isCancelled = false;
    const checkStripe = async () => {
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: countryIsoCode || "US",
          currency: currencyCode?.toLowerCase() || "usd",
          total: {
            label: intl.formatMessage(messages.nativePayRequestCompanyLabel),
            amount: 0,
            pending: true,
          },
          requestPayerName: true,
          disableWallets: ["browserCard"],
        });
        // Check the availability of the Payment Request API.
        if (pr && !isCancelled) {
          setLoading(true);
          const result = await pr.canMakePayment();
          if (result && !isCancelled) {
            setCanUseApplePay(result.applePay);
            setCanUseGooglePay(result.googlePay);
            setPaymentRequest(pr);
          }
          if (!isCancelled) setLoading(false);
        }
      }
    };
    checkStripe();
    return () => {
      isCancelled = true;
    };
  }, [countryIsoCode, currencyCode, intl, stripe]);

  const renderStripeFormFor = (paymentMethodType) => {
    if (speEnabled) {
      return <PaymentElement onChange={onPaymentElementChange} />;
    } else {
      switch (paymentMethodType) {
        case "card":
          return (
            <StripeCardForm
              handlePaymentMethodChange={handlePaymentMethodChange}
            />
          );
        case "sepa_debit":
          return (
            <StripeIbanForm
              handlePaymentMethodChange={handlePaymentMethodChange}
              setBillingDetails={setSepaBillingDetails}
              billingDetails={sepaBillingDetails}
              debitAuthorizationMandate={debitAuthorizationMandate.sepaDebit}
            />
          );
        case "native_pay":
          return (
            <NativePayForm
              handlePaymentMethodChange={handlePaymentMethodChange}
              stripe={stripe}
              paymentRequest={paymentRequest}
              setNativePaySetupIntentResponse={setNativePaySetupIntentResponse}
              hasApplePay={canUseApplePay}
              hasGooglePay={canUseGooglePay}
            />
          );
        default:
          return null;
      }
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {!speEnabled ? (
        <PaymentMethodTypeSelector
          availablePaymentMethodTypes={availablePaymentMethodTypes}
          selectedPaymentMethodType={selectedPaymentMethodType}
          hasApplePay={canUseApplePay}
          hasGooglePay={canUseGooglePay}
          paymentRequest={paymentRequest}
          handlePaymentMethodTypeChange={setSelectedPaymentMethodType}
        />
      ) : null}
      {renderStripeFormFor(selectedPaymentMethodType)}
    </>
  );
};

const mapStateToProps = (state) => {
  const data = state.account?.profile?.data;
  let countryIsoCode;
  let currencyCode;
  if (data) {
    const { countryIsoCode: isoCode, currencyCode: currency } =
      camelCaseKeys(data);
    countryIsoCode = isoCode;
    currencyCode = currency;
  }
  return {
    availablePaymentMethodTypes: state.bootstrap.paymentMethodTypes,
    debitAuthorizationMandate: state.bootstrap.debitAuthorizationMandate,
    countryIsoCode,
    currencyCode,
  };
};

export default connect(mapStateToProps, {
  createSetupIntent: createSetupIntentAction,
  fetchBootstrapData: fetchBootstrapDataAction,
})(injectIntl(PaymentMethodForm));
